import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPage = props => {
  return (
    <Layout>
      <SEO title={'les blog des SES NOAILLES'} />
    </Layout>
  )
}

BlogPage.propTypes = {

}

export default BlogPage
